/* eslint-disable no-alert */
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { addDays, addMonths, format } from 'date-fns'
import { Link, navigate } from 'gatsby'

import useVerifyRole from '../../../hooks/verifyRole'
import Hero from '../../../components/Hero'
import Section from '../../../components/Section'
import SEO from '../../../components/SEO'
import { Button, Input, Loader } from '../../../_shared/components'

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])
  const [createError, setCreateError] = useState(null)
  const [code, setCode] = useState('')
  const [months, setMonths] = useState(3)
  const [expires, setExpires] = useState(format(addMonths(new Date(), 1), 'yyyy-MM-dd'))

  const [createVoucher] = useMutation(gql`
    mutation CreateVoucher($voucher: VoucherData!) {
      createVoucher(voucher: $voucher) {
        success
        error {
          message
        }
      }
    }
  `)

  const { loading: loadingData, data: { voucher } = {} } = useQuery(gql`
    query VoucherLookup($code: String!) {
      voucher(code: $code) {
        id
      }
    }
  `, {
    variables: { code },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <Loader />
  }

  if (!hasRole) {
    return null
  }

  const codeError = (
    (code && !code.match(/^#[a-zA-Z0-9]{4,}$/) && 'Koden må starte med #, være på minst fire tegn (bokstaver og tall)')
    || (code && voucher && 'Koden er allerede i bruk')
    || null
  )

  const isValid = code && !codeError && months > 0 && expires

  async function handleSubmit(e) {
    e.preventDefault()

    if (!isValid) {
      return
    }

    setCreateError(null)

    const res = await createVoucher({
      variables: {
        voucher: {
          code,
          months,
          multiUse: true,
          expires: expires
        }
      }
    })

    if (res?.data?.createVoucher?.error?.message) {
      setCreateError(res.data.createVoucher.error.message)
      return
    }

    if (res?.data?.createVoucher?.success) {
      navigate(`/admin/gavekort/sok?code=${encodeURIComponent(code)}`)
      return
    }

    setCreateError('Rart. Den ble ikke opprettet og det skjedde ikke noe galt')
  }

  return (
    <>
      <SEO title="Gavekort" />
      <Hero title={<>
        <Link to="/admin">Admin</Link>
        {` > `}<Link to="/admin/gavekort">Gavekort</Link>
        {` > `}<br/>Lag flerbruksgavekort
      </>} type={['center', 'small']} />

      <Section size="small">
        <form onSubmit={handleSubmit} style={{ minHeight: 'calc(100vh - 48.3rem)' }}>
          <p>Skriv inn koden du vil bruke. Må starte med #</p>
          <br/>
          <Input
            type="text"
            label="Gavekortkode"
            value={code}
            onChange={setCode}
          />
          {codeError ? <p>{codeError}</p> : null}
          <br/>

          <p>Hvor mange måneder skal gavekortet gi når det løses inn?</p>
          <br/>
          <Input
            type="number"
            label="Antall måneder"
            value={months}
            onChange={v => setMonths(Number(v))}
            min="0"
          />

          <br/>
          <p>Når skal gavekortkoden løpe ut ut?</p>
          <br/>
          <Input
            type="date"
            label="Antall måneder"
            min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
            value={expires}
            onChange={setExpires}
          />
          <br/>

          {createError && <>{createError}<br/></>}
          <Button
            primary
            type="submit"
            disabled={!isValid}
          >Opprett gavekort</Button>
        </form>
      </Section>
    </>
  )
}
